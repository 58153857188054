<template>
  <div class="col-lg-12 col-md-12 col-xl-12">
    <div class="card overflow-hidden mt-2 mb-3">
      <div class="item-card9-img">
        <div class="arrow-ribbon bg-primary">Yeni</div>
        <div class="item-card9-imgs">
          <a :href="'/education/' + item.shortlink + '-EDU' + item.id"
          ></a>
          <img
          :src="'https://storage.terapivitrini.com/' + (item.thumbnail)"
          v-if="item.thumbnail!=null"
            alt="img"
            class="cover-image"
          />
        </div>

      </div>
      <div class="card-body">
        <div class="item-card9">
          <a :href="'/education/' + item.shortlink + '-EDU' + item.id">{{
            item.education_type == 1 ? "Yüz Yüze" : "Online"
          }}</a>
          <a
            :href="'/education/' + item.shortlink + '-EDU' + item.id"
            class="text-dark mt-2"
            ><h4 class="font-weight-semibold mt-1">
              {{ item.title }}
            </h4></a
          >
          <p class="mb-0 leading-tight">
            {{ item.short_description }}
          </p>
          <div class="item-card9-desc">
            <a class=""
              ><span class=""
                ><i class="fa fa-calendar-o text-muted me-1"></i>
                {{ item.begin_date }}</span
              ></a
            >
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="item-card9-footer">
          <div class="item-card9-cost">
            <h4 class="text-dark font-weight-semibold mb-0 mt-0">
              <template v-if="item.dumping_price > 0">
                <span class="float-left">{{ item.dumping_price }}TL</span
                ><span class="float-right"
                  ><del>{{ item.price }}TL</del></span
                >
              </template>
              <template v-else> {{ item.price }}TL </template>
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.float-right{
  float:right !important;
}
</style>
<script>
  export default {
    props:['item']
  }
  </script>