<template>
    <div class="mt-2 mb-2">
        <i class="fa fa-star star" id="star_1" @click="hoverStar(1)"></i>
        <i class="fa fa-star star" id="star_2" @click="hoverStar(2)"></i>
        <i class="fa fa-star star" id="star_3" @click="hoverStar(3)"></i>
        <i class="fa fa-star star" id="star_4" @click="hoverStar(4)"></i>
        <i class="fa fa-star star" id="star_5" @click="hoverStar(5)"></i>
    </div>
</template>
<style scoped>
.star{
    font-size: 20px;
    color:grey;
}
</style>
<script>
export default {
  created() {},
  data() {
    return {
        
    };
  },
  methods: {
    hoverStar(pos){
        if(pos==1){
            document.getElementById('star_1').classList.add('text-yellow')
            document.getElementById('star_2').classList.remove('text-yellow')
            document.getElementById('star_3').classList.remove('text-yellow')
            document.getElementById('star_4').classList.remove('text-yellow')
            document.getElementById('star_5').classList.remove('text-yellow')
        }
        if(pos==2){
            document.getElementById('star_1').classList.add('text-yellow')
            document.getElementById('star_2').classList.add('text-yellow')
            document.getElementById('star_3').classList.remove('text-yellow')
            document.getElementById('star_4').classList.remove('text-yellow')
            document.getElementById('star_5').classList.remove('text-yellow')
        }
        if(pos==3){
            
            document.getElementById('star_1').classList.add('text-yellow')
            document.getElementById('star_2').classList.add('text-yellow')
            document.getElementById('star_3').classList.add('text-yellow')
            document.getElementById('star_4').classList.remove('text-yellow')
            document.getElementById('star_5').classList.remove('text-yellow')
        }
        if(pos==4){
            document.getElementById('star_1').classList.add('text-yellow')
            document.getElementById('star_2').classList.add('text-yellow')
            document.getElementById('star_3').classList.add('text-yellow')
            document.getElementById('star_4').classList.add('text-yellow')
            document.getElementById('star_5').classList.remove('text-yellow')
        }
        if(pos==5){
            document.getElementById('star_1').classList.add('text-yellow')
            document.getElementById('star_2').classList.add('text-yellow')
            document.getElementById('star_3').classList.add('text-yellow')
            document.getElementById('star_4').classList.add('text-yellow')
            document.getElementById('star_5').classList.add('text-yellow')
        }
        this.$emit("response",pos);
    }
  },
};
</script>